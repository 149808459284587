import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layouts/index";

// markup
export default function ReservedPage() {
  return (
    <Layout>
      <Helmet title="VIP Dine - Terms, Conditions and Policies" />
      <br />
      <br />
      <br />
      <br />
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Terms, Conditions and Policies</h1>
              <p>
                These VIP Dine LTD  and Reserved Terms of Use (this “Agreement”)
                apply to your access or use of the websites, mobile sites, and
                applications of, VIP Dine Ltd  and Reserved and the Services,
                Content, and features (the “<b>Services</b>”) made available by
                VIP Dine Ltd  and Reserved.
              </p>

              <p>
                By accessing or using the Services or by clicking “accept” or
                “agree” to this Agreement, (1) you represent and certify that
                you are of legal age and not prohibited by law from accessing or
                using the Services (including, if applicable, purchasing,
                giving, using, or redeeming any vouchers or gift cards (as such
                terms are defined herein)).(2) you acknowledge that you have
                read, understand, and agree to be bound by this Agreement.
              </p>

              <p>
                VIP Dine Ltd  and Reserved may update or revise this Agreement
                (including any Restaurants, VIP Dine Ltd and Reserved Policies)
                from time to time.
              </p>

              <p>
                You agree that you will review this Agreement periodically.{" "}
              </p>

              <p>
                You are free to decide whether to accept a modified version of
                this Agreement, but accepting this Agreement, as modified, is
                required for you to continue using the Services.
              </p>

              <p>
                You may have to click “accept” or “agree” to show your
                acceptance of any modified version of this Agreement.
              </p>

              <p>
                If you do not agree to the terms of this Agreement or any
                modified version of this Agreement, you must terminate your use
                of the Services, in which case you will no longer have access to
                your booking or account (as defined below). Except stated by us,
                any use of the Services (e.g., the use of the Reservation
                Services, Waitlist Services, Payment Services, or the purchase
                of Vouchers or Gift Cards is subject to the version of this
                Agreement in effect at the time of use.
              </p>

              <h2>Part I - Reservation Services</h2>
              <h3>Restaurant Reservations</h3>
              <ol>
                <li>
                  VIP Dine Ltd and Reserved provides platform to the Restaurants
                  (Food Premises Licensees) to receive bookings using our
                  booking tool or booking system systems on their website or
                  online platform.{" "}
                </li>
                <li>
                  It helps the clients make the booking via available restaurant
                  reservation services (the “Reservation Services”) and waitlist
                  services (the “Waitlist Services”) through the Food and
                  Beverage Outlet website to User for the purpose of assisting
                  User in securing dining reservations or joining a waitlist at
                  participating third-party restaurants (each, a “Restaurant”).{" "}
                </li>
                <li>
                  In response to a User’s online request for a Restaurant
                  reservation or to join a waitlist through the Restaurant or
                  Bar Sites, VIP Dine connects with the Restaurant’s
                  computerized database of reservations and waitlists, as
                  applicable.{" "}
                </li>
                <li>
                  The availability of reservations estimated wait time or place
                  in line on a waitlist is determined at the time of User’s
                  query and based on information provided to VIP Dine Ltd or
                  Reserved Booking tool by the Restaurant or Bar.
                </li>
                <li>
                  Once a reservation or waitlist request is made by User through
                  the restaurant Sites using VIP Dine Ltd or Reserved,
                  Restaurant or Bar or Food Outlet will provide confirmation of
                  the reservation or waitlist status to User by email or other
                  electronic messages as has been agreed to by User
                </li>
                <li>
                  By using the Reservation Services or Waitlist Services, User
                  agrees to receive reservation and waitlist confirmations,
                  updates, modifications and/or cancellations by email or other
                  electronic messages
                </li>
              </ol>
              <h3>No-Show Policy</h3>
              <ol>
                <li>
                  VIP Dine Ltd and Reserved is committed to delivering
                  exceptional quality services to Users and Restaurants. To
                  assist us in maintaining a consistently high level of service
                  for the Restaurants and their patrons, Users must cancel any
                  reservations that they will be unable to honor at least 60
                  minutes in advance of the reservation.
                </li>
                <li>
                  You may cancel your reservation via the cancellation link in
                  the confirmation emails or by calling the Restaurant directly.
                </li>
                <li>
                  Some Restaurants may require a debit or credit card number to
                  finalize your reservation.
                </li>
                <li>
                  In order to use the Reservation Services for these
                  Restaurants, you must provide valid debit or credit card
                  information.
                </li>
                <li>
                  To confirm that the debit or credit card information you have
                  provided is accurate, we may place a temporary authorization
                  on your debit or credit card at the time you provide your
                  debit or credit card information. After we verify that your
                  debit or credit card information is accurate, usually within a
                  few days, the authorization will be removed.
                </li>
                <li>
                  You may be required to cancel your reservation in accordance
                  with the Restaurant’s cancellation policy, which is disclosed
                  at the time the reservation is made.
                </li>
                <li>
                  VIP Dine and Reserved platform uses this debit or credit card
                  information and connects it to the restaurants directly and
                  shall have no liability for any charges made to the debit or
                  credit card account for any failure to cancel your reservation
                  in accordance with a Restaurant’s cancellation policy.
                </li>
                <li>
                  By using the Reservation Services, User agrees to receive
                  no-show notifications by email from Restaurant after a report
                  that your reservation was not honoured, whether that was in
                  fact the case.
                </li>
                <li>
                  Your Account may be suspended if you are a no-show for four
                  reservations within a 12-month period.
                </li>
              </ol>

              <h3>Usage Guidelines</h3>
              <p>
                User agrees to use the Reservation Services or Waitlist Services
                only to book reservations or join waitlists at Restaurants and
                then honour those reservations or waitlist requests by arriving
                at the Restaurants on time and ordering and paying for meals.
              </p>
              <p>
                User further agrees not to book more than one reservation or
                join more than one waitlist for User’s personal use during any
                one mealtime (e.g., lunch, dinner, etc.).. Resale or attempted
                resale of reservations or waitlist spots is prohibited and is
                grounds for, among other things, cancellation of your
                reservations, removal from the waitlist or termination of your
                access to the Services.
              </p>

              <h2>Part II – Payment, Gift Card and Other Services</h2>
              <h3>ayment, Gift Card and Other Services</h3>
              <p>
                From time to time, VIP Dine Ltd and Reserved may offer
                additional Services such as payment services made available on
                the platform for Restaurant Sites (the “Payment Services”),
                electronic and physical gift cards and gift certificates issued
                by Restaurants or Food and Beverage Outlets, electronic gift
                cards and gift certificates issued by participating third-party
                restaurants (each, a “Merchant Gift Card”) and other services.
              </p>

              <h2>Part III – Terms for All Services</h2>
              <h3>Privacy Policy.</h3>
              <p>
                VIP Dine and Reserved are committed to helping you safeguard
                your privacy online. Please review our Privacy Policy for
                details about how we collect, use, and disclose information in
                connection with the Services.
              </p>

              <h3>Your Account</h3>
              <p>
                <p>
                  You may (but are not required to) create an account with VIP
                  Dine Ltd or Reserved or The Restaurant Site (“Account”) in
                  order to use the Reservation Services, Waitlist Services or to
                  purchase, gift, or redeem (as applicable).
                </p>
              </p>
              <p>
                However, you must have an Account in order to use the Payment
                Services. When registering for an Account, you must provide
                true, accurate, current, and complete data about yourself on the
                restaurant’s registration form (“Registration Data”).
              </p>
              <p>
                You also agree to promptly update the Registration Data to keep
                it true, accurate, current, and complete.
              </p>
              <p>
                You are solely responsible for maintaining the confidentiality
                of your Account and the information in your Account, and, except
                as otherwise required by applicable law, you are solely
                responsible for all use of your Account, whether or not
                authorized by you.
              </p>
              <p>
                You agree to immediately notify the individual restaurant used
                or VIP Dine Ltd or Reserved of any unauthorized use of your
                Account or any other breach of security related to your use of
                the Services.
              </p>

              <h3>
                Communications from Restaurants, VIP Dine Ltd and Reserved.{" "}
              </h3>
              <p>
                If you use our sites and Services, VIP Dine Ltd , Reserved or
                the individual restaurant where the booking was made may
                communicate with you via electronic messages, including email,
                text message/SMS, or mobile push notifications in accordance
                with our privacy policy
              </p>

              <h3>Technical Requirements. </h3>
              <p>
                Use of the Services requires Internet access through your
                computer or mobile device. You are responsible for all mobile
                carrier data or text message charges resulting from your use of
                the Services, including from any notifications provided by the
                Services.
              </p>
              <p>
                In order to use the text message-based services, you must
                maintain an active account with a carrier of electronic
                communications through mobile devices and you may not use a
                prepaid cellular phone to access such text message services.
              </p>
              <p>
                VIP Dine Ltd and Reserved or the restaurant site does not
                guarantee that the Services will be compatible with all devices
                or will be supported by all mobile carriers.
              </p>
              <p>
                You may be required to have JavaScript (or similar technologies)
                enabled to use the Restaurant Site for using the VIP Dine Ltd
                and Reserved booking platform, and some features and portions of
                the VIP Dine Ltd and Reserved Site (including, but not limited
                to, making, modifying, or canceling reservations) may not be
                accessible with JavaScript disabled.
              </p>

              <h3>Modifications to Services</h3>
              <p>
                VIP Dine Ltd , Reserved and the participating restaurant
                (restaurant site where the booking has been made) reserves the
                right, in its sole discretion, to modify the Services from time
                to time and without notice, including, without limitation, by
                removing, adding, or modifying portions of the Reserved, VIP
                Dine Ltd and Restaurants, and/or Merchants. Restaurants, VIP
                Dine and Reserved shall have no liability to you for any of the
                foregoing actions. If you object to any such changes, your sole
                recourse shall be to cease using the Services. Continued use of
                the Services following any such changes shall indicate your
                acknowledgment of such changes and satisfaction with all the
                Services.
              </p>

              <h3>
                Intellectual Property Rights and Grant of Rights to User.{" "}
              </h3>
              <p>
                The features, information, and materials provided and depicted
                through the Services are protected by copyright, trademark,
                patent, and other intellectual property laws.
              </p>
              <p>
                {" "}
                All text, graphical content, video, data, and other content made
                available through the Services are provided to User by
                Restaurant partner of VIP Dine and Reserved or its partners or
                licensors solely to support User’s permitted use of the
                Services.
              </p>
              <p>
                Except as expressly set forth herein, no license is granted to
                User for any other purpose, and any other use of the Services or
                the Restaurant Content by User shall constitute a material
                breach of this Agreement.
              </p>
              <p>
                VIP Dine and Reserved and its partners or licensors retain all
                rights in the Services and content any associated patents,
                trademarks, copyrights, mask work rights, trade secrets, or
                other intellectual property rights. No license, right, or
                interest in any trademarks of VIP Dine and Reserved or any third
                party is granted under this Agreement.
              </p>

              <h3>Application License</h3>
              <p>
                Subject to the terms and conditions of this Agreement, VIP Dine
                and Reserved or Restaurant and Bar Partner grants User a
                non-exclusive, non-transferable, revocable license to use the
                VIP Dine and Reserved mobile applications, in object code form
                only, on User’s compatible mobile devices, solely to support
                User’s permitted use of the Services.
              </p>

              <h3>Use Restrictions</h3>
              <p>
                The Services and VIP Dine and Reserved Content are offered
                solely for User’s personal use for the purposes described in
                this Agreement.
              </p>
              <p>Any and all other uses are prohibited.</p>
              <p>
                Restaurants, VIP Dine and Reserved where the bookings are being
                made for expressly reserves all its rights and remedies under
                applicable laws (including state, federal, and
                provincial/territorial laws).
              </p>
              <p>
                Restaurants, VIP Dine and Reserved reserves the right, in its
                sole discretion, to refuse service, terminate Accounts, remove
                or edit content, cancel reservations, or deny access to the
                Services.
              </p>

              <p>You agree not to (and not to allow any third party to): </p>

              <p>
                (1) use any deep-link, robot, spider, scraper, or other
                automatic or manual device, process, or means to access, copy,
                search, or monitor any portion of the Services or Restaurants,
                VIP Dine and Reserved Content, except as expressly authorized by
                Restaurants, VIP Dine and Reserved;
              </p>
              <p>
                (2) take any action that imposes or may impose (in Restaurants,
                VIP Dine and Reserved’s sole determination) an unreasonable or a
                disproportionately large load on the Services or Restaurants,
                VIP Dine and Reserved’s infrastructure.{" "}
              </p>
              <p>
                (3) utilize any device, software, or routine that will interfere
                or attempt to interfere with the functionality of the Services;{" "}
              </p>
              <p>
                (4) rent, lease, copy, provide access to or sublicense any
                portion of the Services or Restaurants, VIP Dine and Reserved to
                a third party;{" "}
              </p>
              <p>
                (5) use any portion of the Services or Restaurants, VIP Dine and
                Reserved’s Content to provide, or incorporate any portion of the
                Services or Content into, any product or service provided to a
                third party;{" "}
              </p>
              <p>
                (6) reverse engineer, decompile, disassemble, or otherwise seek
                to obtain the source code or non-public APIs to the Services,
                except to the extent expressly permitted by applicable law (and
                then only upon advance notice to Restaurants, VIP Dine and
                Reserved);{" "}
              </p>
              <p>
                (7) modify any Services or Content or create any derivative
                product from any of the foregoing;{" "}
              </p>
              <p>
                (8) remove or obscure any proprietary or other notices contained
                in the Services or Content ;{" "}
              </p>
              <p>
                (9) use the Services or Restaurants, VIP Dine and Reserved’s
                Content for any illegal purpose; or{" "}
              </p>
              <p>
                (10) publicly disseminate information regarding the performance
                of the Services or Restaurants, VIP Dine and Reserved Content or
                access or use the Services or Restaurants, VIP Dine and Reserved
                Content for competitive analysis or benchmarking purposes.{" "}
              </p>
              <p>
                Although the Restaurants, VIP Dine and Reserved Sites may be
                accessible worldwide, not all features or services discussed,
                referenced, provided or offered through or on the Restaurants,
                VIP Dine and Reserved Sites are available to all persons or in
                all geographic locations, or appropriate or available for use
              </p>

              <h3>Export Control</h3>
              <p>
                You may not use, export, or re-export any Restaurants, VIP Dine
                and Reserved Sites or other aspects of the Services (or any copy
                or adaptation of the foregoing) in violation of applicable law.
              </p>

              <h3>Termination</h3>
              <p>
                Restaurants, VIP Dine and Reserved may suspend your ability to
                use all or any element of the Services or may terminate this
                Agreement effective immediately, without notice or explanation.{" "}
              </p>
              <p>
                Without limiting the foregoing, Restaurants, VIP Dine and
                Reserved may suspend your access to the Services if we believe
                you to be in violation of any part of this Agreement (including
                any Restaurants, VIP Dine and Reserved Policies). After any
                suspension or termination, you may or may not be granted
                permission to use the Services or re-establish an Account, and,
                where applicable, you may lose access to and be unable to use
                any accumulated dining points.
              </p>
              <p>
                You agree that Restaurants, VIP Dine and Reserved shall not be
                liable to you for any termination of this Agreement or for any
                effects of any termination of this Agreement.{" "}
              </p>
              <p>
                You are always free to discontinue your use of the Services at
                any time. You understand that any termination of your Account
                may involve deletion of any content stored in your Account for
                which Restaurants, VIP Dine and Reserved will have no liability
                whatsoever.{" "}
              </p>

              <h3>Your Representations and Indemnity</h3>
              <p>
                You represent and warrant that you own or otherwise control all
                of the rights to any User Content submitted by you; that all
                User Content submitted by you is accurate; and that exploitation
                of such User Content by Restaurants, VIP Dine and Reserved and
                its other Users, partners, and licensees will not violate this
                Agreement, cause injury to any person or entity, or infringe any
                third-party rights (including, without limitation, intellectual
                property rights and rights of privacy or publicity).
              </p>
              <p>
                You will indemnify, hold harmless, and (at Restaurants, VIP Dine
                and Reserved’s request) defend Restaurants, VIP Dine and
                Reserved, its affiliates, and its and their representatives,
                agents, directors, managers, officers, employees, and
                shareholders (collectively, the “Restaurants, VIP Dine and
                Reserved Parties”) from and against all claims resulting from
                (1) any User Content submitted by you, (2) your use of the
                Services, or (3) any breach or alleged breach by you of this
                Agreement.
              </p>

              <h3>Liability Limitations. </h3>
              <p>
                EXCEPT AS EXPRESSLY SPECIFIED HEREIN, IN NO EVENT SHALL THE VIP
                Dine LTD , RESERVED or RESTAURANT PARTNERS HOLDING SITE BE
                LIABLE FOR ANY INJURIES, LOSSES, CLAIMS, OR DIRECT DAMAGES OR
                ANY SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL
                DAMAGES OF ANY KIND, WHETHER BASED IN CONTRACT, TORT, OR
                OTHERWISE, AND EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES, WHICH ARISE OUT OF OR ARE ANY WAY CONNECTED WITH
              </p>
              <p>(1) THIS AGREEMENT (INCLUDING ANY CHANGES THERETO),</p>
              <p>
                (2) ANY USE OF THE RESTAURANT, RESERVED AND SITES, SERVICES, THE
                WEBSITE CONTENT, OR THE USER CONTENT,
              </p>
              <p>
                (3) ANY FAILURE OR DELAY (INCLUDING, BUT NOT LIMITED TO, THE USE
                OR INABILITY TO USE ANY COMPONENT OF ANY OF THE SERVICES), OR
              </p>
              <p>
                (4) YOUR VISIT TO ANY RESTAURANT OR THE PERFORMANCE,
                NON-PERFORMANCE, CONDUCT, OR POLICIES OF ANY RESTAURANT OR
                MERCHANT IN CONNECTION WITH THE SERVICES. IN ADDITION, YOU
                SPECIFICALLY UNDERSTAND AND AGREE THAT ANY THIRD PARTY DIRECTING
                YOU TO THE VIP Dine LTD AND RESERVED SITE BY REFERRAL, LINK, OR
                ANY OTHER MEANS IS NOT LIABLE TO USER FOR ANY REASON WHATSOEVER,
                INCLUDING, BUT NOT LIMITED TO, DAMAGES OR LOSS ASSOCIATED WITH
                THE USE OF THE SERVICES OR THE RESERVED/VIPDINE CONTENT.
              </p>
              <p>
                Restaurants, VIP Dine and Reserved IS NEITHER AN AGENT OF NOR
                OTHERWISE ASSOCIATED WITH ANY RESTAURANT FOR WHICH A USER HAS
                MADE A RESERVATION, CLAIMED AN OFFER OR PROMOTION, OR PAID A
                BILL USING THE PAYMENT SERVICES OR ANY MERCHANT THAT ISSUES A
                MERCHANT GIFT CARD. IFYOU ARE A RESIDENT OF THE UNITED KINGDOM
                OR EUROPEAN UNION, THE LIMITATION OF LIABILITY IN THIS AGREEMENT
                SHALL NOT APPLY TO ANY DAMAGE ARISING FROM OUR WILLFUL
                MISCONDUCT AND GROSS NEGLIGENCE, NOR SHALL IT APPLY TO DAMAGE
                FROM INJURY TO LIFE, BODY OR HEALTH. IF THE DISCLAIMER OF DIRECT
                DAMAGES ABOVE IS NOT ENFORCEABLE AT LAW, EXCEPT AS OTHERWISE
                EXPRESSLY SET FORTH IN THE GIFT CARD TERMS, YOU EXPRESSLY AGREE
                THAT OUR LIABILITY TO YOU (FOR AY CAUSE WHATSOEVER AND
                REGARDLESS OF THE FORM OF THE ACTION) WILL AT ALL TIMES BE
                LIMITED TO FIFTY GREAT BRITISH POUNDS (£50).
              </p>

              <p>
                You and Restaurants, VIP Dine and Reserved understand and agree
                that the disclaimers, exclusions, and limitations in this
                Section 19 and in Section 20 are essential elements of this
                Agreement and that they represent a reasonable allocation of
                risk. In particular, you understand that Restaurants, VIP Dine
                and Reserved would be unable to make the Services available to
                you except on these terms and agree that this Agreement will
                survive and apply even if any limited remedy specified in this
                Agreement is found to have failed of its essential purpose.
              </p>

              <h3>Choice of Law.</h3>
              <p>
                It is agreed that any disputes should be settled in United
                Kingdom and England Laws.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
